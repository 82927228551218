const ModalState = {
    Welcome       : false,
    Machine       : false,
    OpenMachine   : false,
    OpenPDF       : false,
    PurcFinancing : false,
    TalkDealer    : false,
    JDFidelity    : false,
    Bank          : false,
    Consortium    : false,
    Schedule      : false,
    OpenVideo     : false,
};

const ModalData = {
    Welcome       : {},
    Machine       : {},
    OpenMachine   : {},
    OpenPDF       : {},
    PurcFinancing : {},
    TalkDealer    : {},
    JDFidelity    : {},
    Bank          : {},
    Consortium    : {},
    Schedule      : {},
    OpenVideo     : {}
}

const NotifyModalObserver = {
    Welcome       : [],
    Machine       : [],
    OpenMachine   : [],
    OpenPDF       : [],
    PurcFinancing : [],
    TalkDealer    : [],
    JDFidelity    : [],
    Bank          : [],
    Consortium    : [],
    Schedule      : [],
    OpenVideo     : []
}

const NotifyModalData = {
    Welcome       : [],
    Machine       : [],
    OpenMachine   : [],
    OpenPDF       : [],
    PurcFinancing : [],
    TalkDealer    : [],
    JDFidelity    : [],
    Bank          : [],
    Consortium    : [],
    Schedule      : [],
    OpenVideo     : []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key){
    return ModalData[key];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
