import styled from "styled-components";

export const MenuListContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 1130px) {
    position: absolute;
    top: -185px;
    right: 0;
    display: ${({ openMenu }) => (openMenu ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    background-color: #212529;
    box-sizing: border-box;
    border: 2px solid #ffdd00;
    border-radius: 10px;
    .opt_menu {
      border-right: none !important;
      padding: 10px 20px !important;
      justify-content: flex-end !important;
      border-bottom: 1px solid #343a40;
      :last-child{
        border-bottom: none;
      }
    }
  }
`;
