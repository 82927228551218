import React, { useState, useEffect } from "react";

import './PurcFinancing.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function Modals_PurcFinancing(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showImg, setShowImg ] = useState('');

    useEffect(()=>{
        RegisterModalData('PurcFinancing', setModaldata);
        RegisterModalObserver('PurcFinancing', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
        }
    }, [showPopUp]);

    function ClosePopUp(){
        props.AltStatus();
        SetModalState('PurcFinancing', false);
    }

    function OpenPopUp_Bank(){
        SetModalState('Bank', true); // PopUp
    }
    
    function OpenPopUp_Consortium(){
        SetModalState('Consortium', true); // PopUp
    }

    return(
        (showPopUp ?
            <div className="Modals_PurcFinancing">
                <div className="div_data">
                    <div className="show_data" style={ { width: props.width } }>
                        <a onClick={ ()=>{ OpenPopUp_Bank() } } className="div_opt">
                            <div className="title">Banco</div>
                            <div className="subtitle">De pequeno ao grande empreendedor</div>
                        </a>
                        <div className="border_right" />
                        <a onClick={ ()=>{ OpenPopUp_Consortium() } } className="div_opt">
                            <div className="title">Consórcio</div>
                            <div className="subtitle">Planejar é o caminho para realizar</div>
                        </a>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
            : <React.Fragment></React.Fragment>
        )
    )
}