import { useState, useEffect, useRef } from "react";

import {
  SvgHouse,
  SvgLogoTipo,
  SvgLogoTipo_1,
  SvgMenuHamburguer,
  SvgSonsClose,
  SvgSonsOpen,
} from "components/SvgFile";

import { SetModalState } from "interface/PopUp";

import Modals_Machine from "components/Modals/Machine";
import Modals_PurcFinancing from "components/Modals/PurcFinancing";

import PopUp_Welcome from "components/PopUp/Welcome";
import PopUp_JDFidelity from "components/PopUp/JDFidelity";
import PopUp_Schedule from "components/PopUp/Schedule";
import PopUp_TalkDealer from "components/PopUp/TalkDealer";
import PopUp_Machine from "components/PopUp/Machine";
import PopUp_OpenPDF from "components/PopUp/OpenPDF";
import PopUp_OpenVideo from "components/PopUp/OpenVideo";
import PopUp_Bank from "components/PopUp/Bank";
import PopUp_Consortium from "components/PopUp/Consortium";
import { MenuListContainer } from "style";

export default function App() {
  const showMenu = useRef();

  const [statusSons, setStatusSons] = useState(true);
  const [statusMachine, setStatusMachine] = useState(false);
  const [statusPurcFinancing, setStatusPurcFinancing] = useState(false);
  const [widthMenu, setWidthMenu] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWidthMenu(showMenu?.current.clientWidth);
    }, 100);
  }, []);

  useEffect(() => {
    if(statusSons){
      window.MusicVolumeOn();
    }else{
      window.MusicVolumeOff();
    }
  }, [statusSons]);

  useEffect(() => {
    if (statusMachine == true) {
      setWidthMenu(showMenu?.current.clientWidth);
    }
  }, [statusMachine]);

  useEffect(() => {
    if (statusPurcFinancing == true) {
      setWidthMenu(showMenu?.current.clientWidth);
    }
  }, [statusPurcFinancing]);

  function AltStatus() {
    setStatusMachine(false);
    setStatusPurcFinancing(false);
  }

  function OpenModal(type) {
    switch (type) {
      case "machine":
        setStatusMachine(!statusMachine);
        setStatusPurcFinancing(false);
        SetModalState("Machine", !statusMachine); // Modals
        SetModalState("PurcFinancing", false); // Modals
        break;
      case "purcFinancing":
        setStatusMachine(false);
        setStatusPurcFinancing(!statusPurcFinancing);
        SetModalState("Machine", false); // Modals
        SetModalState("PurcFinancing", !statusPurcFinancing); // Modals
        break;
    }
  }

  function OpenPopUp(type) {
    setStatusMachine(false);
    setStatusPurcFinancing(false);
    SetModalState("Machine", false); // Modals
    SetModalState("PurcFinancing", false); // Modals
    switch (type) {
      case "talkDealer":
        SetModalState("TalkDealer", true); // PopUp
        break;
      case "jDFidelity":
        SetModalState("JDFidelity", true); // PopUp
        break;
      case "checkSchedule":
        SetModalState("Schedule", true); // PopUp
        break;
    }
  }

  return (
    <>
      <div className="Contents">
        <div className="div_menu">
          <Modals_Machine width={widthMenu} AltStatus={AltStatus} />
          <Modals_PurcFinancing width={widthMenu} AltStatus={AltStatus} />
          <div className="menu" ref={showMenu}>
            <div className="opt_menu">
              <SvgLogoTipo className="width_icons_desktop" />
              <SvgLogoTipo_1 className="width_icons_mobile" />
            </div>
            <div className="opt_menu">
              <div className="">
                <SvgHouse className="icons" />
              </div>
              <div
                className=""
                onClick={() => {
                  setStatusSons(!statusSons);
                  setOpenMenu(false);
                }}
              >
                {statusSons ? (
                  <SvgSonsOpen className="icons" />
                ) : (
                  <SvgSonsClose className="icons" />
                )}
              </div>
            </div>
            <div
              className="opt_menu menu_close"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            >
              <SvgMenuHamburguer className="icons" />
              <div className="name_menu">Menu</div>
            </div>
            <MenuListContainer openMenu={openMenu}>
              <div
                className="opt_menu opt_name_menu menu_active"
                onClick={() => {
                  OpenModal("machine");
                  setOpenMenu(false);
                }}
              >
                <div className="name_menu">Equipamentos</div>
              </div>
              <div
                className="opt_menu opt_name_menu menu_active"
                onClick={() => {
                  OpenModal("purcFinancing");
                  setOpenMenu(false);
                }}
              >
                <div className="name_menu">Compra e financiamento</div>
              </div>
              <div
                className="opt_menu opt_name_menu menu_active"
                onClick={() => {
                  OpenPopUp("talkDealer");
                  setOpenMenu(false);
                }}
              >
                <div className="name_menu">Fale com concessionário</div>
              </div>
              <div
                className="opt_menu opt_name_menu menu_active"
                onClick={() => {
                  OpenPopUp("jDFidelity");
                  setOpenMenu(false);
                }}
              >
                <div className="name_menu">John deere fidelidade</div>
              </div>
              <div
                className="opt_menu opt_name_menu menu_active"
                onClick={() => {
                  OpenPopUp("checkSchedule");
                  setOpenMenu(false);
                }}
              >
                <div className="name_menu">Programação</div>
              </div>
            </MenuListContainer>
          </div>
        </div>
      </div>
      <PopUp_Welcome />
      <PopUp_TalkDealer />
      <PopUp_JDFidelity />
      <PopUp_Schedule />
      <PopUp_Machine />
      <PopUp_OpenPDF />
      <PopUp_OpenVideo />
      <PopUp_Bank />
      <PopUp_Consortium />
    </>
  );
}
