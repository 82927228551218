import React, { useState, useEffect } from "react";

import './Bank.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgClose } from "components/SvgFile";
import { GetDataPage } from "interface/Data";

export default function PopUp_Bank(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showImg, setShowImg ]   = useState('');
    const [ showLink, setShowLink ] = useState('');

    useEffect(()=>{
        RegisterModalData('Bank', setModaldata);
        RegisterModalObserver('Bank', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowImg(GetDataPage('purc_financing_bank').img);
            setShowLink(GetDataPage('purc_financing_bank').link);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        SetModalState('Bank', false);
    }

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="popup_position">
                    <div className="all popup_bank">
                        <div className="div_icon_fixed">
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { padding: 0 } }>
                            <div className="content">
                                <a href={ showLink } target="_blank">
                                    {
                                        showImg == '' ? 
                                        <img alt="img" src="./assets/loading.gif" className="img_gif" /> : 
                                        <img alt="img" src={ showImg } className="img_fid" />
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
            : <React.Fragment></React.Fragment>
        )
    )
}