import React, { useState, useEffect, useRef } from "react";

import './TalkDealer.css';

import Axios from 'axios';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgArrow, SvgClose, SvgWhatsapp } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { connectionPage } from "fixedData";

export default function PopUp_TalkDealer(props){

    const heightState = useRef();

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ state, setState ]             = useState('Estado');
    const [ statusState, setStatusState ] = useState(false);

    const [ city, setCity ]             = useState('Cidade');
    const [ statusCity, setStatusCity ] = useState(false);

    const [ dataCard, setDataCard ] = useState('');

    const [ listState, setListState ] = useState({});
    const [ nameState, setNameState ] = useState([
        { "key": "mato_grosso", "name": "Mato Grosso" },
        { "key": "tocantins", "name": "Tocantins" },
        { "key": "bahia", "name": "Bahia" },
        { "key": "piaui", "name": "Piauí" },
        { "key": "rio_grande_do_sul", "name": "Rio Grande do Sul" },
        { "key": "mato_grosso_do_sul", "name": "Mato Grosso do Sul" },
        { "key": "santa_catarina", "name": "Santa Catarina" },
        { "key": "sao_paulo", "name": "São Paulo" },
        { "key": "rio_grande_do_norte", "name": "Rio Grande do Norte" },
        { "key": "amazonas", "name": "Amazonas" },
        { "key": "roraima", "name": "Roraima" },
        { "key": "amapa", "name": "Amapá" },
        { "key": "parana", "name": "Paraná" },
        { "key": "goias", "name": "Goiás" },
        { "key": "maranhao", "name": "Maranhão" },
        { "key": "minas_gerais", "name": "Minas Gerais" },
        { "key": "espirito_santo", "name": "Espírito Santo" },
        { "key": "distrito_federal", "name": "Distrito Federal" },
        { "key": "rondonia", "name": "Rondônia" },
        { "key": "acre", "name": "Acre" },
        { "key": "sergipe", "name": "Sergipe" },
        { "key": "alagoas", "name": "Alagoas" },
        { "key": "rio_de_janeiro", "name": "Rio de Janeiro" },
        { "key": "pernambuco", "name": "Pernambuco" },
        { "key": "ceara", "name": "Ceará" },
        { "key": "paraiba", "name": "Paraíba" }
    ]);

    useEffect(()=>{
        RegisterModalData('TalkDealer', setModaldata);
        RegisterModalObserver('TalkDealer', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            Axios({
                url     : connectionPage + '/concessionaires.json',
                mode    : 'no-cors'
            })
            .then(response => {
                setListState(response.data);
            });
        }
    }, [showPopUp]);

    useEffect(()=>{
        if(showPopUp == true){
            if(statusCity == true){
                ListCity()
            }
        }
    }, [state]);

    useEffect(()=>{
        if(showPopUp == true){
            if(state != 'Cidade'){
                ListCard()
            }
        }
    }, [city]);

    function ListCity(){
        if(state != 'Estado'){
            let showKey   = nameState.find(item => item.name == state);
            const newData = [];
            Object.keys(listState).map((key, index)=>{
                if(key == showKey.key){
                    newData.push(listState[key]);
                }
            });

            return(
                <div className="list_data">
                    {
                        newData[0].map((key, index)=>{
                            let nameCity = key.cidade.toLowerCase();
                            return(
                                <div className="name_inf" key={ index } onClick={ ()=>{ setCity(nameCity); setStatusState(false); setStatusCity(false); setDataCard(key) } }>
                                    { nameCity }
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        return '';
    }

    function ListCard(){
        if(state != 'Cidade'){
            if(dataCard !=''){
                return(
                    <div className="show_card">
                        <div className="title_card">{ dataCard.dealer }</div>
                        <div className="show_data_card">
                            <div className="icon_phone">
                                <SvgWhatsapp className="icons" />
                            </div>
                            <div className="show_phone">{ dataCard.contato }</div>
                        </div>
                    </div>
                )
            }
            return '';
        }
        return '';
    }

    function ClosePopUp(){
        setState('Estado')
        setStatusState(false);

        setCity('Cidade');
        setStatusCity(false);

        setListState({});
        setDataCard(''); 
        SetModalState('TalkDealer', false);
    }

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="popup_position">
                    <div className="all popup_talkdealer">
                        <div className="div_icon_fixed">
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { padding: 0 } }>
                            <div className="content">
                                <div className="title">
                                    ENCONTRE UM CONCESSIONÁRIO PRÓXIMO DE VOCÊ
                                </div>
                                <div className="list_opt">
                                    <div className="div_inf">
                                        <div className="name_data" onClick={ ()=>{ setStatusState(!statusState); setStatusCity(false) } }>
                                            { state }
                                            <SvgArrow className="icons" />
                                        </div>
                                        {
                                            statusState == false ? null :
                                            <div className="list_data">
                                                {
                                                    Object.keys(listState).map((key, index)=>{
                                                        let showName = nameState.find(item => item.key == key);
                                                        if(showName){
                                                            return(
                                                                <div className="name_inf" key={ index } onClick={ ()=>{ setState(showName.name); setStatusState(false); setStatusCity(false); setCity('Cidades'); setDataCard(''); } }>
                                                                    { showName.name }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="div_inf">
                                        <div className="name_data" onClick={ ()=>{ setStatusState(false); setStatusCity(!statusCity) } }>
                                            { city }
                                            <SvgArrow className="icons" />
                                        </div>
                                        {
                                            statusCity == false ? null : ListCity()
                                        }
                                    </div>
                                </div>
                                {
                                    ListCard()
                                }
                                <div className="subtitle">
                                    Para mais informações e outras localizações, <a href="https://dealerlocator.deere.com/servlet/country=BR" target="_blank"> acesse aqui.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
            : <React.Fragment></React.Fragment>
        )
    )
}