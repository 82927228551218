import React, { useState, useEffect } from "react";

import "./Welcome.css";

import {
  RegisterModalData,
  RegisterModalObserver,
  SetModalState,
} from "interface/PopUp";

import { SvgClose } from "components/SvgFile";

export default function PopUp_Welcome(props) {
  const [modalData, setModaldata] = useState({});
  const [showPopUp, setShowPopUp] = useState(true);

  useEffect(() => {
    RegisterModalData("Welcome", setModaldata);
    RegisterModalObserver("Welcome", setShowPopUp);
  }, []);

  function ClosePopUp() {
    SetModalState("Welcome", false);
    // window.initsound();
    window.PlayVideoStart();
  }

  return (
    showPopUp && (
      <div className="PopUp">
        <div className="popup_position">
          <div className="all popup_welcome">
            <div className="div_topic">
              <div
                className="close"
                onClick={() => {
                  ClosePopUp();
                }}
              >
                <SvgClose color="#F00000" className="icons" />
              </div>
            </div>
            <div className="div_data" style={{ paddingTop: 0 }}>
              <div className="content">
                <div className="welcome_title">
                  <span>Agrishow 2023</span>
                  <h3>É UM PRAZER RECEBER VOCÊ</h3>
                </div>
                <div className="show_type_pin">
                  <img src="./assets/pinIcon1.png" className="show_pin" />
                  <img src="./assets/pinIcon2.png" className="show_pin" />
                </div>
                <div className="text">
                  Clique nos pins espalhados pelo ambiente para navegar pela
                  plataforma e interagir com os conteúdos.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="close_all"
          onClick={() => {
            ClosePopUp();
          }}
        />
      </div>
    )
  );
}
