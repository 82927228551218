import React, { useState, useEffect } from "react";

import "./Schedule.css";

import {
  RegisterModalData,
  RegisterModalObserver,
  SetModalState,
} from "interface/PopUp";

import { SvgClockOutline, SvgClose } from "components/SvgFile";

import { GetDataPage } from "interface/Data";

export default function PopUp_Schedule(props) {
  const [modalData, setModaldata] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);

  const [weekSchedule, setWeekSchedule] = useState({});
  const [weekSelected, setWeekSelected] = useState(false);

  useEffect(() => {
    RegisterModalData("Schedule", setModaldata);
    RegisterModalObserver("Schedule", setShowPopUp);
  }, []);

  useEffect(() => {
    if (showPopUp == true) {
      setWeekSchedule(GetDataPage("lecture"));
    }
  }, [showPopUp]);

  useEffect(() => {
    if (showPopUp == true) {
      setWeekSchedule(weekSchedule);
    }
  }, [weekSelected]);

  function HandleWeek(index, value) {
    const newData = weekSchedule;
    Object.keys(weekSchedule).map((key, index_1) => {
      weekSchedule[key].status = false;
    });
    newData[index].status = value;
    setWeekSchedule(newData);
    setWeekSelected(!weekSelected);
  }

  function InitialDay() {
    const newData = [];
    Object.keys(weekSchedule).map((key, index) => {
      if (weekSchedule[key].status == true) {
        Object.keys(weekSchedule[key].data).map((key_1, index_1) => {
          newData.push(weekSchedule[key].data[key_1]);
        });
      }
    });

    return (
      <div className="list_day">
        {newData.map((key, index) => {
          return (
            <div
              className={
                key.thematic == "Intervalo" ? "show_day interval" : "show_day"
              }
              key={index}
            >
              {/* {key.thematic == "Intervalo" ? null : (
                <div className="show_thematic">{key.thematic}</div>
              )} */}
              {key.list.map((key_1, index_1) => {
                return (
                  <div className="show_details" key={index_1}>
                    <div className="show_hour">
                      <SvgClockOutline className="icons_hour" />
                      {key_1.startHour} - {key_1.endHour}
                    </div>
                    <div className="show_title">
                      {key.thematic == "Intervalo" ? "Intervalo" : key_1.title}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  function ClosePopUp() {
    SetModalState("Schedule", false);
  }

  return showPopUp ? (
    <div className="PopUp">
      <div className="all popup_schedule">
        <div className="div_icon_fixed">
          <div
            className="close"
            onClick={() => {
              ClosePopUp();
            }}
          >
            <SvgClose color="#F00000" className="icons" />
          </div>
        </div>
        <div className="div_data" style={{ paddingTop: 0 }}>
          <div className="content">
            <div className="title">Arena do conhecimento</div>
            <div className="subtitle">Acompanhe a programação ao vivo</div>
            <div className="week">
              {Object.keys(weekSchedule).map((key, index) => {
                return (
                  <div
                    className={
                      weekSchedule[key].status == true
                        ? "show_week active"
                        : "show_week"
                    }
                    key={index}
                    onClick={() => {
                      weekSchedule[key].status == true
                        ? HandleWeek(index, true)
                        : HandleWeek(index, !weekSchedule[key].status);
                    }}
                  >
                    <div className="">{weekSchedule[key].startDate}</div>
                    <div className="name_week">{weekSchedule[key].week}</div>
                  </div>
                );
              })}
            </div>
            {InitialDay()}
          </div>
        </div>
      </div>
      <div
        className="close_all"
        onClick={() => {
          ClosePopUp();
        }}
      />
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
