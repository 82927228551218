import React, { useState, useEffect, useRef } from "react";

import './OpenPDF.css';

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.parcel2";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgArrow_1, SvgClose, SvgDonwload } from "components/SvgFile";

export default function PopUp_OpenPDF(){
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ file, setFile ]         = useState('');

    const [ numPages, setNumPages ]     = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);

	const goToPrevPage          = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
	const goToNextPage          = () => setPageNumber( pageNumber + 1 >= numPages ? numPages : pageNumber + 1, );

    useEffect(()=>{
        RegisterModalData('OpenPDF', setModaldata);
        RegisterModalObserver('OpenPDF', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setFile(modalData.file);  
        }
    }, [showPopUp]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function ClosePopUp(){
        SetModalState('OpenPDF', false);
    }

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="popup_position">   
                    <div className="all popup_openpdf">                    
                        <div className="div_close_fixed" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>                 
                        <div className="div_data" style={ { padding: 0 } }>
                            <div className="content">
                                {
                                    modalData.file == '' ? null :
                                    <Document file={ modalData.file } onLoadSuccess={ onDocumentLoadSuccess }>
                                        <Page pageNumber={ pageNumber } />
                                    </Document>
                                }

                                <div className="div_opt_alt">
                                    <div className="pag">{ pageNumber }/{ numPages }</div>
                                    <div className="div_button">
                                        <div className="show_arrow" onClick={ goToPrevPage }>
                                            <SvgArrow_1 className="icons_" />
                                        </div>
                                        <div className="show_arrow" onClick={ goToNextPage }>
                                            <SvgArrow_1 className="icons_ icons_right" />
                                        </div>
                                    </div>
                                    <div className="download">
                                        <a href={ file } download={ modalData.name } target="_blank">
                                            <SvgDonwload className="icons" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
            : <React.Fragment></React.Fragment>
        )
    )
}