
let DataPage = {
    "lecture"     : [],
    "speaker"     : [],
    "machine"     : [],
    "jd_fidelity" : {},

    "purc_financing_bank"       : {},
    "purc_financing_consortium" : {}
};

let NotifyDataPage = {
    "lecture"     : [],
    "speaker"     : [],
    "machine"     : [],
    "jd_fidelity" : [],

    "purc_financing_bank"       : [],
    "purc_financing_consortium" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["lecture"].forEach(element => {
        element(value['lecture']);
    });
    NotifyDataPage["speaker"].forEach(element => {
        element(value['speaker']);
    });
    NotifyDataPage["machine"].forEach(element => {
        element(value['machine']);
    });
    NotifyDataPage["jd_fidelity"].forEach(element => {
        element(value['jd_fidelity']);
    });
    NotifyDataPage["purc_financing_bank"].forEach(element => {
        element(value['purc_financing_bank']);
    });
    NotifyDataPage["purc_financing_consortium"].forEach(element => {
        element(value['purc_financing_consortium']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
