import React, { useState, useEffect } from "react";

import './OpenVideo.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgArrow_1, SvgClose, SvgDonwload } from "components/SvgFile";

export default function PopUp_OpenImg(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('OpenVideo', setModaldata);
        RegisterModalObserver('OpenVideo', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
        }
    }, [showPopUp]);

    function ClosePopUp(){
        SetModalState('OpenVideo', false);
        window.resumeSound();
    }

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="popup_position">
                    <div className="all popup_openimg">                    
                        <div className="div_topic" style={{padding: "12px"}} onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" style={{padding: "4px"}} />
                        </div>                    
                        <div className="div_data" style={ { padding: 0 } }>
                            <div className="content">
                            <div className="video-iframe">
                                <iframe src={modalData.url}  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
            : <React.Fragment></React.Fragment>
        )
    )
}