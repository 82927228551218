// Connection type
// const pageServidor = 'http://localhost/React_JS/agrishow_2023/site/public';
const pageServidor  = 'https://agrishow2023-site.chroma-garden.com';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor        = 'localhost';
const cookiesServidor         = 'agrishow2023-site.chroma-garden.com/';
export const cookiesRegister  = cookiesServidor;

// Cookies name
export const typeCookie       = 'agrishow2023_dash';
export const typeCookiePassw  = 'agrishow2023_passw';
export const typeCookieEmail  = 'agrishow2023_email';
export const typeCookiePage   = 'agrishow2023_page';
export const typeCookiePageId = 'agrishow2023_page_id';
export const typeCookieNewId  = 'agrishow2023_new_id';