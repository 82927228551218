import React, { useState, useEffect } from "react";

import './Machine.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { GetDataPage } from "interface/Data";

export default function PopUp_Machine(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ showData, setShowData ]   = useState('');

    useEffect(()=>{
        RegisterModalData('OpenMachine', setModaldata);
        RegisterModalObserver('OpenMachine', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowData(InitialData());
        }
    }, [showPopUp]);

    function InitialData(){
        const dataMachine = GetDataPage('machine');
        const newData     = [];
        Object.keys(dataMachine).map((key, index)=>{
            if(dataMachine[key].id == modalData.id){
                newData.push(dataMachine[key]);
            }
        });
        return newData[0];
    }

    function OpenFile(){
        ClosePopUp()
        SetModalData('OpenPDF', { "file": showData.datasheet, "name": showData.name + "" + showData.reference + ".pdf" });
        SetModalState('OpenPDF', true);
    }

    function ClosePopUp(){
        SetModalState('OpenMachine', false);
    }

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="popup_position">
                    <div className="all popup_openmachine">
                        <div className="div_icon_fixed">
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { padding: 0 } }>
                            <div className="content div_data_tractor">
                                <div className={ showData.imgExample == '' ? "show_data_tractor space_padding" : "show_data_tractor" }>
                                    {
                                        showData.imgExample == '' ? null :
                                        <div className="div_img">
                                            <img alt={ showData.reference } src={ showData.imgExample } className="show_img" />
                                        </div>
                                    }
                                    <div className="tractor_data">
                                        <div className="subtitle">
                                            { showData.name }
                                            <div className="title">{ showData.reference }</div>
                                        </div>
                                        <div className="description" dangerouslySetInnerHTML={ { __html: showData.obs ? showData.obs.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />                                    
                                    </div>
                                </div>
                                <div className="div_button">
                                    <div className="button">
                                        <a href={ "" + showData.link + "" } className="link" target="_blank">
                                            <div>Veja em nosso site</div>  
                                        </a>  
                                    </div>
                                    <div className="button">
                                        <div className="open_file" onClick={ ()=>{ OpenFile() } }>Folheteria</div>
                                    </div>                                              
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
            : <React.Fragment></React.Fragment>
        )
    )
}