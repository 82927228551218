import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { InitializeFirebase } from "services/Firebase.js";

import { connectionPage } from 'fixedData';

import { SetListData, GetDataPage } from 'interface/Data';

import { SetModalData, SetModalState } from "interface/PopUp";

const root = ReactDOM.createRoot(document.getElementById('root'));

InitializeFirebase();

Axios({
    url     : connectionPage + '/list_data.json',
    mode    : 'no-cors'
})
.then(response => {
    
    console.log(response.data);
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});

window.ExternalOpenModalMachine = (ref) => {

    const MachineObject = GetDataPage("machine");

    Object.keys(MachineObject).map((key, index)=>{
        if(MachineObject[key].reference == ref){
            SetModalData('OpenMachine', { "id": MachineObject[key].id });
            SetModalState('OpenMachine', true);
        }
    });
}

window.ExternalOpenVideo = (url) => {
    SetModalData('OpenVideo', { "url": url });
    SetModalState('OpenVideo', true);
}

window.ExternalOpenVideoMaquina = (ref) => {
    const MachineObject = GetDataPage("machine");

    Object.keys(MachineObject).map((key, index)=>{
        if(MachineObject[key].reference == ref){
            SetModalData('OpenVideo', { "url": MachineObject[key].specialist });
            SetModalState('OpenVideo', true);
        }
    });    
}
