import React, { useState, useEffect, useRef } from "react";

import "./Machine.css";

import {
  RegisterModalData,
  RegisterModalObserver,
  SetModalData,
  SetModalState,
} from "interface/PopUp";

import { GetDataPage } from "interface/Data";

import "pure-react-carousel/dist/react-carousel.es.css";

export default function Modals_Machine(props) {
  const scrolContainer = useRef(null);
  const [modalData, setModaldata] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);

  const [listMachine, setListMachine] = useState({});

  const scrollMachineList = (event) => {
    const { deltaY } = event;
    const scrollUp = deltaY < 0;

    if (scrollUp) {
      //   console.log("scrollUp", deltaY);
      event.target.scrollBy(500, 0);
    } else {
      //   console.log("scrollDown", deltaY);
      event.target.scrollBy(-500, 0);
    }
  };

  const scrollContainerToLeft = () => {
    scrolContainer?.current?.scrollBy(-200, 0);
  };

  const scrollContainerToRight = () => {
    scrolContainer?.current?.scrollBy(200, 0);
  };

  useEffect(() => {
    RegisterModalData("Machine", setModaldata);
    RegisterModalObserver("Machine", setShowPopUp);
  }, []);

  useEffect(() => {
    if (showPopUp == true) {
      setListMachine(GetDataPage("machine"));
      ShowScroll();
    }
  }, [showPopUp]);

  function ClosePopUp() {
    props.AltStatus();
    SetModalState("Machine", false);
  }

  function OpenMachine(id) {
    SetModalData("OpenMachine", { id: id }); // Page
    SetModalState("OpenMachine", true); // Page
    ClosePopUp();
  }

  function ShowScroll() {
    const showDataMachine = setInterval(() => {
      const show_machine = document.getElementById("show_machine");
      if (show_machine) {
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
          mouseDown = true;
          startX = e.pageX - show_machine.offsetLeft;
          scrollLeft = show_machine.scrollLeft;
        };

        let stopDragging = function (event) {
          mouseDown = false;
        };

        show_machine.addEventListener("mousemove", (e) => {
          e.preventDefault();
          if (!mouseDown) {
            return;
          }
          const x = e.pageX - show_machine.offsetLeft;
          const scroll = x - startX;
          show_machine.scrollLeft = scrollLeft - scroll;
        });

        // Add the event listeners
        show_machine.addEventListener("mousedown", startDragging, false);
        show_machine.addEventListener("mouseup", stopDragging, false);
        show_machine.addEventListener("mouseleave", stopDragging, false);

        clearInterval(showDataMachine);
      }
    }, 100);
  }

  return showPopUp ? (
    <div className="Modals_Machine">
      <div className="div_data">
        <div className="show_data" style={{ width: props.width }}>
          <span
            className="arrow left-arrow"
            onClick={scrollContainerToLeft}
          ></span>
          <div className="mask_shadow" />
          <div
            className="list_machine"
            id="show_machine"
            onWheel={scrollMachineList}
            ref={scrolContainer}
          >
            {Object.keys(listMachine).map((key, index) => {
              return (
                <div className="show_tractor" key={index}>
                  <div className="reference">
                    <b>{listMachine[key].reference}</b>
                  </div>
                  <div className="name">{listMachine[key].name}</div>
                  <div
                    className="div_img"
                    onClick={() => {
                      OpenMachine(listMachine[key].id);
                    }}
                  >
                    <img
                      alt=""
                      src={listMachine[key].thumbnail}
                      className="img_tractor"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <span
            className="arrow right-arrow"
            onClick={scrollContainerToRight}
          ></span>
        </div>
      </div>
      <div
        className="close_all"
        onClick={() => {
          ClosePopUp();
        }}
      />
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
}
