import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onChildChanged, set, runTransaction, update, onValue, onChildAdded, onChildRemoved, push, serverTimestamp, query, limitToLast   } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDmrGtZfUQqeECLaoqem1ey5ItkPU4OaB4",
    authDomain: "jd-agrishow-2022.firebaseapp.com",
    databaseURL: "https://jd-agrishow-2022-default-rtdb.firebaseio.com",
    projectId: "jd-agrishow-2022",
    storageBucket: "jd-agrishow-2022.appspot.com",
    messagingSenderId: "1072481742095",
    appId: "1:1072481742095:web:b52fd98c98feb5a48a627a",
    measurementId: "G-SMWTJ95RV3"
};

const FirebaseData = {
    app: null,
    analytics: null,
    database: null    
};

export function InitializeFirebase(){
    FirebaseData['app'] = initializeApp(firebaseConfig);
    FirebaseData['analytics'] = getAnalytics(FirebaseData['app']);
    FirebaseData['database'] = getDatabase(FirebaseData['app']);  
}

export function RegisterCount(refClick){    
    const countRef = ref(FirebaseData['database'], 'register2023/click_'+refClick);
    runTransaction(countRef, (click) => { 
        click++;        
        return click;
    });
}

window.ExternalRegisterCount = (ref) => {
    RegisterCount(ref);
}